html,
body,
#root {
  margin: 0;
  padding: 0;
  height: 100%;
}

/* Set preset ranges to the right because they don't fit in a footer */
.date-picker-dropdown .ant-picker-footer {
  border-left: 1px solid #f0f0f0;
  display: inline-flex;
  min-width: auto;
}

.ant-tabs {
  flex-grow: 1;
}

.ant-tabs-content-holder {
  display: flex;
  padding: 0 1rem;
}
